<template>
  <el-dialog title="批量查询导出" :visible.sync="isAddDialog" :close-on-click-modal="false" width="1300px" :before-close="dialogClose">
    <div style="width:100%;">
      <el-form style="text-align:left" label-width="100px" ref="form" :model="form" inline size="small">
        <el-form-item label="状态：">
          <el-select style="width:120px;" v-model="search.integralWithdrawalState" placeholder="选择状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="未发放" value="0"></el-option>
            <el-option label="已发放" value="1"></el-option>
            <el-option label="已退回" value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="搜索：">
          <el-input style="width:160px;" placeholder="请输入名称/手机号/卡号/身份证" v-model="search.search"></el-input>
        </el-form-item> -->
        <el-form-item label="提交时间：">
          <el-date-picker v-model="search.submitStartTime" type="datetime" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker> 至
          <el-date-picker v-model="search.submitEndTime" type="datetime" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker>
        </el-form-item>
        <el-form-item label="发放时间：">
          <el-date-picker v-model="search.grantStartTime" type="datetime" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker> 至
          <el-date-picker v-model="search.grantEndTime" type="datetime" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker>
        </el-form-item>
        <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
        <el-form-item label="Excel模板">
          <a style="margin:0px;display:block;width:200px" href="https://template.gongxingtech.com/提现-发放查询模板.xlsx">下载模板</a>
        </el-form-item>
        <el-form-item label="导入模板">
          <el-upload :class="typeObj.total?'upload-demo':''" :data="search" ref="upload" accept=".xls,.xlsx" :action="`${url}/wlynIntegralWithdrawal/batchGetIntegralWithdrawalPage`" :before-remove="beforeRemove" :on-remove="handleRemove" :on-exceed="onExceed" :on-success="handleSuccess" :before-upload="beforeUpload" :on-progress="onProgress" :on-error="onError" :headers="headers" :limit="1">
            <el-button size="small" type="primary" v-if="!typeObj.total">点击上传</el-button>
            <span slot="tip" style="margin-left:20px" class="el-upload__tip" v-if="!typeObj.total">只允许上传Excel文件</span>
          </el-upload>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table border stripe :data="list" height="300px" size="mini">
        <el-table-column align="center" label="序号" width="90" type="index"></el-table-column>
        <el-table-column prop="submitOrgName" align="center" label="提交机构"></el-table-column>
        <!-- <el-table-column prop="number" align="center" label="编号" width="100"></el-table-column> -->
        <el-table-column prop="contactPeopleName" align="center" label="客户"></el-table-column>
        <el-table-column prop="userPhone" align="center" label="使用者号码"></el-table-column>
        <el-table-column prop="contactPhone" align="center" label="银行预留号码"></el-table-column>
        <el-table-column prop="amount" align="center" label="面额（元）" width="90"></el-table-column>
        <el-table-column prop="createTime" align="center" label="提交时间" width="150"></el-table-column>
        <el-table-column prop="isUse" align="center" label="状态" width="80">
          <template slot-scope="scope">
            <el-tag class="el-tag-style" size="mini" type="primary" disable-transitions v-if="scope.row.integralWithdrawalState == 0">未发放</el-tag>
            <el-tag class="el-tag-style" size="mini" type="success" disable-transitions v-if="scope.row.integralWithdrawalState == 1">已发放</el-tag>
            <el-tag class="el-tag-style" size="mini" type="warning" disable-transitions v-if="scope.row.integralWithdrawalState == 2">已退回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="grantTime" align="center" label="发放/退回时间" width="150">
          <template slot-scope="scope">{{scope.row.integralWithdrawalState == 2?scope.row.returnTime:scope.row.grantTime}}</template>
        </el-table-column>
      </el-table>
      <div style="display:flex;width:100%">
        <!-- <p style="line-height:30px;width:200px">总数 : {{totalCount || 0}}</p> -->
        <el-pagination style="text-align:center;flex:1" layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
        </el-pagination>
      </div>
      <div style="margin-top:20px">
        <el-button type="primary" v-debounce="sureImport">确认导出</el-button>
        <el-button type="info" @click="dialogClose">关闭窗口</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>

import OrganizationSelect from '@/components/OrganizationSelect.vue'
import { exportBatchExcel } from '@/api/issueList';
import { baseURL } from '@/utils/http';
import { getCookies, newExportsExcel, exportCommon, ExportsExcel } from '@/utils/utils'
export default {
  props: ['isAddDialog'],
  components: { OrganizationSelect },
  data() {
    return {
      list: [],
      url: baseURL,
      headers: {
        'x-token': getCookies('x-token')
      },
      typeObj: {
        errorData: [],
        successData: []
      },
      form: {
        orgId: getCookies('organizationId'),
        isSendSms: false,
        title: '',
        auditCode: '',
        remark: '',
        grantType: '',
        grantTime: ''
      },
      search: {
        integralWithdrawalState: '',
        submitOrg: '',
        // search: '',
        submitStartTime: '',
        submitEndTime: '',
        grantStartTime: '',
        grantEndTime: '',
        pageNum: 1,//分页页数
        pageSize: 10,//每页显示多少
        isPage: 1,
        // file: {}
      },
      total: 0,//分页总页数
      // totalCount: ''
    }
  },
  created() {
    this.getList()
  },
  filters: {
    toThousands(num = 0) {
      let c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      return c
    }
  },
  methods: {
    getList() {
      let formData = new FormData()
      console.log(formData);
      formData.set('integralWithdrawalState', this.search.integralWithdrawalState)
      formData.set('submitOrg', this.search.submitOrg)
      formData.set('submitStartTime', this.search.submitStartTime)
      formData.set('submitEndTime', this.search.submitEndTime)
      formData.set('grantStartTime', this.search.grantStartTime)
      formData.set('grantEndTime', this.search.grantEndTime)
      formData.set('pageNum', this.search.pageNum)
      formData.set('pageSize', this.search.pageSize)
      formData.set('isPage', this.search.isPage)
      console.log(this.search.file);
      if (this.search.file) {
        formData.set('file', this.search.file || '')
      }
      // countIntegralWithdrawalAmount(this.search).then(res => {
      //   this.totalCount = res.data
      // })
      newExportsExcel('/wlynIntegralWithdrawal/batchGetIntegralWithdrawalPage', formData)
        .then((response) => {
          if (response.code == 200) {
            console.log(formData.file);
            this.total = response.data.total;
            this.list = response.data.records;
            console.log(this.list);
          }
        })
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.pageNum = val;
      this.getList();
    },
    sureImport() {

      // param.submitOrg = param.submitOrg || getCookies('organizationId')
      // console.log(param);
      // exportCommon("/wlynIntegralWithdrawal/exportBatchExcel", this.search);
      let formData = new FormData()
      console.log(formData);
      formData.set('integralWithdrawalState', this.search.integralWithdrawalState)
      formData.set('submitOrg', this.search.submitOrg)
      formData.set('submitStartTime', this.search.submitStartTime)
      formData.set('submitEndTime', this.search.submitEndTime)
      formData.set('grantStartTime', this.search.grantStartTime)
      formData.set('grantEndTime', this.search.grantEndTime)
      formData.set('pageNum', this.search.pageNum)
      formData.set('pageSize', this.search.pageSize)
      formData.set('isPage', this.search.isPage)
      console.log(this.search.file);
      if (this.search.file) {
        formData.set('file', this.search.file || '')
      }
      var myDate = new Date();
      var Time2 = myDate.toISOString().slice(0, 10)//推荐 yyyy-MM-dd
      ExportsExcel('/wlynIntegralWithdrawal/exportBatchExcel', formData).then((response) => {
        // let blob = new Blob([response], {
        //   type: 'multipart/form-data'// 前后端一定要同意utf-8编码，否则会是乱码
        // });
        // let blob = new Blob([response], { type: "application/vnd.ms-excel" });
        let blob = new Blob([response], { type: "application/vnd.ms-excel" });
        // 获取heads中的filename文件名
        let downloadElement = document.createElement('a');
        // 创建下载的链接
        let href = window.URL.createObjectURL(blob);
        console.log(href);
        downloadElement.href = href;
        // 下载后文件名
        downloadElement.download = `批量查询提现名单-${Time2}`;
        document.body.appendChild(downloadElement);
        // 点击下载
        downloadElement.click();
        // 下载完成移除元素
        document.body.removeChild(downloadElement);
        // 释放掉blob对象
        window.URL.revokeObjectURL(href);
      }).catch(err => {
      })

    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onChange(file) {

      console.log(file);
    },
    handleSuccess(e, file) {
      console.log(file)
      // this.file = file
      console.log(e);
      this.search.file = file.raw
      console.log(this.search.file);
      if (e.code == 200) {
        this.list = e.data.records || []
        this.typeObj = e.data || {}
      } else {
        this.$refs['upload'].clearFiles()
      }
      this.$message({
        message: e.msg || e.error_msg || e.message || '上传失败',
        type: e.code == 200 ? 'success' : 'error'
      });
    },
    beforeUpload() {
      if (!this.form.orgId) {
        this.$message.warning('请选择提交机构')
        return false
      }
    },
    onProgress(event, file, fileList) {
      console.log(event, file, fileList);
    },
    onError() {
      this.$message.error('上传失败')
    },
    onExceed() {
      this.$message.error('最多只能上传一个文件')
    },

    beforeRemove(e) {
      return this.$confirm('确定删除该文件？')
    },
    handleRemove() {
      this.list = []
      delete this.search.file
      this.getList()
      this.typeObj = {}
    }
  }
}
</script>
<style scoped>
.upload-demo /deep/ .el-upload {
  display: none;
}
</style>
